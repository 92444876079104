<template>
  <div class="body-main">
    <div class="main">
      <bread-crumb :list="breadCrumbList"></bread-crumb>
      <div class="main-1">
        <div class="main-1-l">
          <el-image
            class="main-img"
            :src="productInfo.product_img"
            alt
          ></el-image>
        </div>
        <div class="main-1-m">
          <el-form
            ref="ruleForm"
            :model="form"
            :rules="rules"
            label-width="100px"
          >
            <el-form-item prop="date" label="包车时间：">
              <el-date-picker
                class="rent-car-date"
                v-model="form.date"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="包车时间"
                :picker-options="pickerOptions"
              >
                <i slot="prefix" class="el-input__icon el-icon-time"></i>
              </el-date-picker>
            </el-form-item>
            <el-form-item label="包车地区：">
              <el-input
                :disabled="true"
                :placeholder="productInfo.city_nm"
              ></el-input>
            </el-form-item>
            <el-form-item label="产品项目：">
              <el-input
                :disabled="true"
                :placeholder="productInfo.product_nm"
              ></el-input>
            </el-form-item>
            <el-form-item label="服务车型：">
              <el-input
                :disabled="true"
                :placeholder="$route.query.car_type_nm"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="box-1">
            <div class="box-1-1">
              <i class="icon"></i>
              <div class="test">
                乘客数 {{ $route.query.car_passenger_num }}
              </div>
            </div>
            <div class="box-1-2">
              <i class="icon"></i>
              <div class="test">行李数 {{ $route.query.car_luggage_num }}</div>
            </div>
          </div>
        </div>
        <div class="main-1-r">
          <div class="box-2">服务时间：{{ $route.query.service_time }}</div>
          <div class="box-2">服务里程：{{ $route.query.service_mileage }}</div>
          <div class="box-2">配备服务：{{ carInfo && carInfo.eq_service }}</div>
          <div class="box-2">车辆保险：{{ $route.query.car_car_insure }}</div>
          <div class="box-2">
            包车价格：{{ $route.query.car_order_car_price }}
          </div>
          <div @click="submitForm" class="box-3">
            {{ $route.query.target === 'personal' ? '继续' : '加入购物车' }}
          </div>
        </div>
      </div>
      <div class="main-2">
        <div class="main-2-l">包车详情</div>
        <div class="main-2-r" v-html="$route.query.product_desc"></div>
      </div>
      <div class="main-2">
        <div class="main-2-l">变更条例</div>
        <div class="main-2-r" v-html="productInfo.chg_regulations"></div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb';
import {
  orderCarBillSearch,
  orderCarProductSearch,
  orderCarTypeSearch,
} from '@/api/index';
export default {
  name: 'rentCarDetail',
  components: {
    BreadCrumb,
  },
  data: function () {
    return {
      breadCrumbList: [
        {
          name: '包车',
          path: '',
          query: '',
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            },
          },
          {
            text: '明天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', date);
            },
          },
          {
            text: '一周后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            },
          },
        ],
      },
      orderCarBillSearchList: [],
      form: {
        date: '',
      },
      rules: {
        date: [{ required: true, message: '请输入包车时间', trigger: 'blur' }],
      },
      queryObj: {},
      carInfo: {},
      productInfo: {},
    };
  },
  created() {
    // 数据回填
    this.dataInit();
    this.orderCarTypeSearchFunc();
    this.orderCarProductSearchFunc();
  },
  methods: {
    dataInit() {
      this.form.date = this.$route.query.bill_date;
    },
    orderCarTypeSearchFunc() {
      orderCarTypeSearch({ id: [this.$route.query.car_item_id] }).then(
        (res) => {
          console.log(222, res);
          this.carInfo = res.data && res.data[0];
        }
      );
    },
    orderCarProductSearchFunc() {
      orderCarProductSearch({ id: [this.$route.query.id] }).then((res) => {
        console.log(333, res);
        this.productInfo = res.data && res.data[0];
      });
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$router.push({
            path: '/rent-car/order',
            query: {
              date: this.form.date,
              ...this.$route.query,
            },
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.body-main {
  width: 100%;
  display: flex;
  background: rgba(245, 245, 245, 1);
  justify-content: center;
  .main {
    width: 1200px;
    .main-1 {
      margin-bottom: 20px;
      background-color: #ffffff;
      border: 1px solid rgba(221, 221, 221, 1);
      border-radius: 2px;
      padding: 45px 0 35px 50px;
      display: flex;
      align-content: center;
      &-l {
        width: 30%;
        text-align: center;
        .main-img {
          // width:160px;
          width: 80%;
          height: 120px;
        }
      }
      &-m {
        width: 40%;
        font-size: 14px;

        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        // ::v-deep .el-form-item {
        //   margin-bottom: 10px;
        //   line-height: 30px;
        // }
        :deep(.el-form-item__label),
        :deep(.el-form-item__content) {
          line-height: 30px;
        }
        :deep(.el-input),
        :deep(.el-input__inner) {
          width: 300px;
          height: 30px;
          line-height: 30px;
          border-radius: 4px;
          background: #fff;
          cursor: default;
          &::-webkit-input-placeholder {
            color: #666666;
          }
        }
        .rent-car-date {
          :deep(.el-input__inner) {
            cursor: pointer;
            color: rgba(61, 126, 255, 1);
            &::-webkit-input-placeholder {
              color: rgba(61, 126, 255, 1);
            }
            border: 1px solid rgba(61, 126, 255, 1);
          }
          :deep(.el-input__icon) {
            color: rgba(61, 126, 255, 1) !important;
            line-height: 30px;
          }
        }
        .box-1 {
          display: flex;
          justify-content: center;
          &-1 {
            display: flex;
            align-content: center;
            margin-right: 20px;
            i {
              margin-right: 4px;
              display: inline-block;
              width: 24px;
              height: 24px;
              background: url('../../assets/index/icon-010.png') no-repeat;
            }
            .test {
              line-height: 24px;
            }
          }
          &-2 {
            display: flex;
            align-content: center;
            margin-right: 10px;
            i {
              margin-right: 4px;
              display: inline-block;
              width: 24px;
              height: 24px;
              background: url('../../assets/index/icon-009.png') no-repeat;
            }
            .test {
              line-height: 24px;
            }
          }
        }
      }
      &-r {
        width: 30%;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 28px;
        .box-3 {
          display: inline-block;
          cursor: pointer;
          width: 132px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: rgba(255, 168, 37, 1);
          border-radius: 2px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          margin-top: 20px;
        }
      }
    }
    .main-2 {
      margin-bottom: 20px;
      padding: 30px;
      display: flex;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(221, 221, 221, 1);
      border-radius: 2px;
      align-items: center;
      &-l {
        width: 200px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 16px;
      }
      &-r {
        border-left: 1px solid rgba(221, 221, 221, 1);
        padding-left: 25px;
        flex: 1;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 30px;
      }
    }
  }
}
</style>